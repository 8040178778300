.underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;  /* Optional: control line thickness */
  text-underline-offset: 2px;     /* Optional: control distance from text */
}

.personalized-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2e2e2e; /* Dark background */
    padding: 10px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    margin-bottom: 20px; /* Space below */
}

.personalized-bar p {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff; /* White text */
}

.mode-toggle {
    display: flex;
    gap: 10px; /* Space between the buttons */
}

.toggle-btn {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    background-color: #e0e0e0; /* Default background */
    color: #333;
    font-weight: bold;
    font-size: 20px;
}

.toggle-btn.active {
    background-color: #007bff; /* Active mode: blue for practice */
    color: white; /* #fff  #f16522*/
}

/*.toggle-btn:not(.active) {
     /* e0e0e0 is same grey as other parts of site. background-color: #ff6347; Test mode: red 
     background-color: #e0e0e0;
     color: #333333; 
}
*/

.sat-area{
  display: flex;
  flex-direction: row;
}

/*Skill BUtton Display below:*/
.skill-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 4 columns */
    grid-auto-rows: 50px;
    gap: 18px; /* Spacing between boxes */
    margin-left: auto;
    margin-right: auto;
    padding: 0%;
    align-items: start;
    max-width: 95%; /*this will make it even with question-display */
  }

.skill-progress-box {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    /*padding: 10px; 
    margin-top: 10px; */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items:center;
    transition: transform 0.6s ease, padding 0.6s ease; /* Smooth transition */
    position: relative; /* Allows for smoother layout control */
    min-height: 30px; 
    max-height: 50px;
    width: 100%;
    height: 100%;
    /*height: auto; */
  }

  .skill-progress-box.unselected {
    padding: 5px; /* Smaller padding for unselected boxes */
    font-size: 14px; /* Smaller font for unselected boxes */
    justify-content: center;
  }
  
  .skill-progress-box.selected {
    /* Back when this used to increase the size of the box, I used this:
    transform: scale(1.15); 
    grid-column: span 2.0;
    grid-row: span 2;
    padding: 25px;
    height: auto;  Auto height for selected boxes  
    width: auto;
    max-height: 300px;
    max-width: 300px;
    transition: all 0.6s ease;*/
    background-color: #f16522; /* Orange color during generation */
    color: #ffffff; /* White text */
  }

  .skill-progress-summary{
    display: flex;
    justify-content: space-evenly; /* Evenly space the buttons */
  }
  
  .skill-title {
    text-align: center;
    font-weight: bold;
    align-items: center;
    /*margin-bottom: 10px;*/
    color: inherit;
  }
  
  .progress-grid {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr .5fr 1fr;
    gap: 5px;
  }
  
  .progress-row {
    display: contents;
    margin-bottom: 5px;
  }
  
  .difficulty-name {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  
  .progress-percentage, .progress-completed {
    font-size: 16px;
    text-align: center;
  }
  
  .generate-btn {
    text-align: right;
  }


  /* Make the grid responsive */
@media (max-width: 1200px) {
    .skill-grid {
      grid-template-columns: repeat(6, 1fr); /* 4 columns when the screen is smaller */
    }
  }
  
  @media (max-width: 900px) {
    .skill-grid {
      grid-template-columns: repeat(4, 1fr); /* 3 columns for medium-sized screens */
    }
  }
  
  @media (max-width: 600px) {
    .skill-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for smaller screens */
    }
  }
  
  @media (max-width: 400px) {
    .skill-grid {
      grid-template-columns: 1fr; /* 1 column for very small screens */
    }
}



/* PROGRESS BAR */
.progress-markers {
    display: grid;
    grid-template-columns: repeat(9, 1fr); /* Ensure 9 equal columns for the 9 markers */
    margin-top: 5px;
    align-items: center;
  }
  
  .progress-markers span,
  .progress-markers button {
    text-align: center;
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
    color: black;
  }
  
  .progress-markers button {
    background-color: #ff6600; /* Adjust button style */
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .progress-markers button:hover {
    background-color: #ff4500;
  }


  /* QUESTION AND ANSWER DISPLAY AT BOTTOM */
  /* Styling for answer choice buttons */
  .centered-skill-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .generate-question-buttons-by-difficulty-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; /* Evenly space the buttons */
    /*margin-bottom: 5px;  /* Add space between buttons and question text */
  }

  /*
  .generate-question-difficulty-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    /*padding-top:5px;
    padding-bottom: 15px; 
    width: 30%;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    line-height: 1px;
  }

  .generate-question-difficulty-column p{
    margin: 1px;
  }*/

  .generate-question-difficulty-row{
    display: flex;
    flex-direction: column;
    justify-content: center; /*previously was flex-start when the flex-direction was row*/
    align-items: center; /* vertical alignment when this was flex-direction: row. Not sure if I need it now.*/
    gap: 5px; /*not sure if I need this with columns. tbd*/
  }
  
  
  .generate-question-buttons .cta-button {
    background-color: #e0e0e0;  /*Default grey */
    border: 1px solid #ddd;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    transition: background-color 0.3s ease;  /*Smooth color transition */
    cursor: pointer;
  }
  
  .generate-question-buttons .cta-button:hover {
    background-color: #007bff;  /*Change background color on hover */
    color: white;
  }


  /*
.choices-and-explanation-container{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;  
} */

.explanation-container{
  flex: 1;
}

.explanation{
  background-color: #f9f9f9;
  padding: 15px;
  /*border-left: 4px solid #d3d3d3; */
}
  
.choices-grid {
  display: flex;
  /*min-width: 25%; Minimum width of 25% */
  /*max-width: 50%; Maximum width of 55% */
  flex-direction: column; /* Stack buttons vertically */
  align-items: left; /* Center the buttons horizontally */
  margin-right: 5 px; /*Opposite direction of what you think */
  margin-bottom: 5px; /* Adjust spacing between buttons */
}

.answer-choice-btn {
  display: flex;  /* Flexbox to align elements properly */
  justify-content: left;  /* Center content horizontally */
  align-items: center;  /* Center content vertically */
  flex-wrap: wrap;  /* Allow text to wrap if too long */
  flex: 1 1 auto; /* Allow the button to grow and shrink as needed */
  /*width: fit-content;  Let the button adjust automatically */
  margin-bottom: 5px; /* Add spacing between the buttons */
  background-color: #e0e0e0; /* Default grey */
  border: 1px solid #ddd;
  padding: 0px 10px;
  cursor: pointer;
  /*font-size: 16px;*/
  font-weight: bold;
  border-radius: 10px;
  transition: background-color 0.3s ease; /* Smooth color transition */
}

.choice-text {
  display: inline-flex; /* Change to inline-flex to fit content */
  align-items: center; /* Center content vertically */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

.katex  {
  font-family: inherit; /*Ensure it inherits the font of the website*/
  font-size: inherit; /*
  display: inline-block; PRevent it from affecting other elements' alignment
  white-space: pre-wrap; */
}

/*
.katex .katex-html, .katex .base, .katex .strut, .katex .pstrut, .katex .mord, .katex .underline-line{
  white-space: pre-wrap; 
  line-height: 1.2;
  display: inline-block;
  overflow-wrap: break-word;
  line-height: inherit; 
  height: auto !important; /* Override any specific height settings 
  vertical-align: baseline !important; /* Align them with the baseline 
} 

.katex .mord {
  white-space: pre-wrap !important;
} */


.answer-choice-btn:hover{
  background-color: #ddd;
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover for a more dynamic look */
}

.answer-choice-btn.selected {
  background-color: #63d5fe; /* Light lue for selected */
  color: white;
}

.answer-choice-btn.correct-answer {
  background-color: #4cd963; /* Green for correct answer */
  color: white;
}

.answer-choice-btn.incorrect-answer {
  background-color: #fd7272; /* Less intense red for incorrect answer */
  color: white;
}

.answer-choice-btn:disabled {
  cursor: not-allowed;
}

/* New on 11/14: Border and feedback styles for free response Math input */
.correct-border {
  border: 2px solid #4CAF50;
}

.incorrect-border {
  border: 2px solid #fd7272;
}

.feedback-message {
  margin-top: 8px;
  font-weight: bold;
}

.correct-feedback {
  color: #4CAF50;
}

.incorrect-feedback {
  color: #fd7272;
}



/* Speech-to-Text SecondPage.css */
.speech-to-text-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;  /* w-10 in Tailwind */
  height: 50px; /* h-10 in Tailwind */
  border-radius: 50%; /* rounded-full */
  background-color: #f16522;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for all changes */
}

.speech-to-text-button:hover {
  background-color: #d55411;
}

.speech-to-text-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #f16522, 0 0 0 4px white; /* Creates the ring effect */
}

.speech-to-text-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.speech-to-text-button svg {
  width: 20px;  /* w-5 in Tailwind */
  height: 20px; /* h-5 in Tailwind */
  color: white;
}
