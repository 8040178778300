/*Previous font-family:
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
*/
body {
  margin: 0;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #ffffff;
    color: #333333;
    

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #f16522;
  font-size: 2em; /*previously 36px*/
  /*margin-bottom: 20px;*/
}
/*
h2 {
  color: #f16522;
  font-size: 30 px;
  margin-bottom: 18px;
}

h3 {
  font-size: 24px;
  margin-bottom: 15px;
} */

p {
  font-size: 2.4vh; /*previously 18px*/
  line-height: 1.5;
  color: #333333;
}

li {
  font-size: 18px;
  line-height: 1.6;
  color: #333333;
}

h4, h5, h6 {
  color: #f16522;
}

img {
  max-width: 100%;
  height: auto;

}

a{
    color: #f16522;
    text-decoration: none; 
}
a:hover{
    text-decoration: underline; 
}

/* Utility classes for padding and margin */
.p-1 { padding: 1rem; }
.m-1 { margin: 1rem; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Footer */
footer {
  background-color: #F4F4F8;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}
