.debug{
  color: black;
  background-color: yellow;
}

.App {
  text-align: center;
  z-index: 0; /* Background and pseudo-elements remain in this context */
  /* new experimental styles below */
  min-height: 100vh;
  position: relative;
  background-color: #ffffff;
  background-image: 
    linear-gradient(30deg, #f16522 12%, transparent 12.5%, transparent 87%, #f16522 87.5%, #f16522),
    linear-gradient(150deg, #f16522 12%, transparent 12.5%, transparent 87%, #f16522 87.5%, #f16522),
    linear-gradient(30deg, #f16522 12%, transparent 12.5%, transparent 87%, #f16522 87.5%, #f16522),
    linear-gradient(150deg, #f16522 12%, transparent 12.5%, transparent 87%, #f16522 87.5%, #f16522),
    linear-gradient(60deg, #f1652277 25%, transparent 25.5%, transparent 75%, #f1652277 75%, #f1652277),
    linear-gradient(60deg, #f1652277 25%, transparent 25.5%, transparent 75%, #f1652277 75%, #f1652277);
  background-size: 80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  /*opacity: 0.95;*/
}

.App::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.97); /* Subtle white overlay */
  pointer-events: none;
}

.App-content {
  position: relative;
  z-index: 1; /* Ensure the content is above .App::after */
}

.App-logo {
  height: 40 px; /* 10vmin previously Use viewport units for responsive sizing */
  pointer-events: none;
  justify-content: flex-start;
  max-height: 12vh;
  width: auto;
  margin-left: 1vw;
  object-fit:contain; /* Make sure the logo is completely visible */
}


.App-header {
   /*background-color: #f8f9fa; previously #ffffff*/
  background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0.5) 50%,
        rgba(0, 0, 0, 0) 100%
    ); /* Gradient from dark to transparent */
  /*backdrop-filter: blur(2px);  Optional: Adds a blur effect for a more modern look */
  height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: #f16522; /*Previously #f16522, orange */
  padding: 0 3vw;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  position: absolute; /*formerly sticky: starts with relative at top, then fixed as the page scrolls down*/
  top: 0;
  z-index: 1000;
}

/* Positions title in center. transform: translateX(-50%) moves 50% of the width of the element to the left */
.App-header h1{
  position: relative; 
  /* Instead of left: 20%, added "margin-left/right"
  transform: translateX(-50%); Not necessary now that the margin-right is established and it's within the App-header flex box
  */
  margin-right: auto;
  margin-left: 3vw; /*Should be equally distanced from side as Login button on the right side.*/
  
}

/* Fix main element spacing */
main {
  flex: 1;
  width: 100%;
  /*margin: 0;
  padding: 0;*/
  background-color: #ffffff;
}


.nav-bar{
  margin-left: auto; /*allows space for Login button next to it, no longer in nav-bar */
  margin-right: 2vw; /*puts a small amount of space between this and the Login button */
  /*Apparently margin-right is like pushing an arm out as far as possible, so it actually goes to the left, so I should do 'left' */
  margin-top: 0rem; /*matches login-button below...margin creates space around element, but doesn't move it*/
  padding: 0.5rem 1rem; /* Adjust padding to match other nav links */
  font-size: 2.0rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 2vw;
  flex-wrap: nowrap;
  gap: 30px;
  /* background-color: #ffffff; or make this semi-transparent with dark background?
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  */
}
 
/* previously added to the nav-link below but it makes global changes: .cta-button */
.nav-link{
  padding: 1%;
  margin: 0;
  font-size: 1.5rem;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-left: 2%;
  margin-right: 2%;
  /*color: #ffffff; 
  font-weight: bold;
  line-height: 1.5;*/
}

.nav-link:hover{
  text-decoration: underline;
}

.login-button{
  position:relative; /*should this be absolute or relative?
  
  ChatGPT's recommendation on 9/2 at 10:30 am: Make the button absolutely positioned
  position: absolute;
  top: 10px;  Align with the top of the header 
  right: 3vw;  Place it 3vw from the right edge 
  */
  margin-top: 0rem; /*relative to App.header */
  margin-right: 3vw;
  background-color: #f16522;
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  /*font-size: 1.2rem; */
  cursor: pointer;
  line-height: 1.5;
}
  
  .login-button:hover {
    background-color: #d1551c;
}


.login-dropdown {
  position: absolute;
  top: 15vh; 
  right: 3vw; 
  background-color: white;
  border: 1px solid #f16522; 
  border-radius: 15px;
  padding: 10px 15px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none; /*hidden by default*/
  width: 25vw; 
  max-width: 400px; 
}
/* potential things to add back to login-dropdown:
opacity: 0; /* Hidden by default 
transform: translateY(-20px); /* Positioned above its final location 
transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth fade and slide in */
.login-dropdown.show {
  display: block; /* Show when the "show" class is applied */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .login-dropdown {
    width: 80vw; /* Expand to most of the viewport width on smaller screens */
    top: 20vh; /* Adjust positioning for smaller screens */
    right: 10vw; /* Center the dropdown more on smaller screens */
    max-width: none; /* Remove max-width to allow full responsiveness */
  }
}

@media (max-width: 480px) {
  .login-dropdown {
    width: 90vw; /* Almost full screen width on very small screens */
    top: 15vh; /* Further adjust top position */
    right: 5vw; /* Center more on small screens */
  }
}

/* fancier version of dropdown.show:
.login-dropdown.show {
  animation: slide-down 0.5s ease-out forwards; /* Apply slide-down animation 
}  
  .login-dropdown.hide {
  opacity: 0; /* Fade out 
  transform: translateY(-20px); /* Slide up to initial position 
}
*/

/* Slide-down animation 
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px); /* Start slightly above 
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at the final position 
  }
}
*/

.App-link {
  color: #61dafb;
}

@media (max-width: 768px) {
  .button-container {
    gap: 10px; /* Reduced gap for smaller screens */
  }
  .skill-button {
    padding: 10px 14px; /* Reduced padding for smaller screens */
    /*font-size: 16px;  Slightly smaller font */
  }
}


.button-container {
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  gap: 20px;
  padding: 0px 20px;
  /*
  border-radius: 10px;
  background-color: #f7f7f7; Light gray background for contrast
  */
}

.cta-button {
  background-color: #f16522;
  color: white;
  padding: 0.5rem 1.0rem;
  /*margin-top: 1rem;*/
  border: none;
  border-radius: 10px;
  text-decoration: none;
  /*font-size: 1.2rem; */
  cursor: pointer;
  line-height: 1.5;
  box-shadow: 0 rpx 8px rgba(0, 0, 0, 0.1); /* tiny shadow added on 9/2/2024 */
  max-height: 50px;
}

.cta-button:hover {
  background-color: #d1551c;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover for a more dynamic look */
}


/* SAT Overview Section */
.SAT-overview {
  margin-top: 50px;
  padding: 0px 20px;
  background-color: #f9f9f9;
  text-align: left;
}

.SAT-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.SAT-text {
  flex: 1;
  font-size: 1.1em;
  line-height: 1.6em;
  text-align: left;
  margin-left: 5vw;
}

.SAT-text ul {
  list-style-type: disc;
  margin-top: 10px;
}

.SAT-text ul li {
  margin-bottom: 10px;
}

.SAT-image {
  /*background-image: url('../public/images/thrilled-student.JPG');*/
  flex: 1;
  text-align: center;
  margin-right: 5vw;
  margin-left: 5vw;
  margin-top: 5vh;
  max-width: 80%;
  height: auto;
  border-radius: 50px;
}

/*Feature section (not present now but I can input this back in */
.features .container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 1rem;
}

.features-grid {
  display: flex;
  gap: 2rem; /* Adjust the gap between columns as needed */
}

.feature {
  flex: 1; /* Distribute the columns evenly */
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
}

.hero-home-page {
  background-image: url('../public/images/MIT-lawn-background.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  text-align: center;
  /*padding: 5rem 1rem;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20vh;
  position: relative;
  z-index: 0;
}

.hero-second-page {
  background-image: url('../public/images/picture.jpg');
  background-size: cover;
  background-position: center 30%;
  height: 15vh;
  color: white;
  text-align: center;
  padding: 5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20vh;
  position: relative;
  z-index: 1;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better text readability */
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  max-width: 40vw;
}

/*.hero-content-text h2 {
  color: #f16522;
  margin-top: 5px;
  /*font-size: 10px + 3em;
  margin-bottom: 20px;
} */

.hero p {
  font-size: 5px + 1.5em;
  margin-bottom: 20px;
}

.form-input {
  padding: 0.75rem 1rem;
  color:#333333;
  margin-top: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 45%; /* Adjust width as needed */
  font-size: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.form-input:focus {
  outline: none;
  border-color: #007AFF; /* Same blue as iPhone text */
  box-shadow: 0 0 5px rgba(0, 122, 255, 0.5);
}
/*
.main-menu {
  margin: 0px 0;
} */

.menu-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.menu-button:hover {
  transform: scale(1.1);
}

.menu-button.selected {
  background-color: #4CAF50;
  color: #ffffff;
}

.menu-button:not(.selected) {
  background-color: #f0f0f0;
  color: #333;
}

.menu-button:not(.selected):hover {
  background-color: #e0e0e0;
}

.question-generator {
  margin-top: 20px;
}

.question-display {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /*text-align: left;*/
  background-color: #f9f9f9;
  color: #333333;
  padding: 10px;
  border-radius: 10px;
  max-width: 95%; /* Increase width */
  margin-left: auto;
  margin-right: auto;
  width: 100%; /* Ensure it uses all available space */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for a dynamic effect */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.question-display h4 {
  color:#f16522; /*Keep header orange*/
}

.question-display:hover {
   /*transform: translateY(-5px); Slightly lift the box on hover */
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover for a more dynamic look */
}

.question-display pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

/*
.question-display-left-section{
  width: 60%;
  padding-right: 1vw;
} */

.skills-and-questions-left-section {
  width: 62%;
  padding-right: 1vw;
}

.chat-component-right-section{
  width: 38%;
  border-left: 1px solid #ccc; /* Optional: A divider to separate the sections */
}


.ChatComponent{
  position: sticky;
  top: 20px;
} 

.quick-prompts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 1vw;
  gap: 5px;
   /*margin-bottom: 1rem; Adds space between the prompt bubbles and the chat input - unnecessary because form already has large top-margin */
}

.quick-prompt-bubble {
  width: 90%;
  background-color: #ffd699; /* Light orange to stay consistent with your color scheme but appear softer */
  color: #333; /* Darker text for readability */
  padding: 0.4rem 1rem;
  border-radius: 20px; /* Creates a pill shape */
  border: 1px solid #f0ad4e; /* Subtle border for definition */
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500; /* Slightly lighter than bold for a more casual feel */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
  transition: background-color 0.2s ease, transform 0.2s ease; /* Smooth transitions */
}

.quick-prompt-bubble:hover {
  background-color: #f16522; /* Darker orange to show interactivity */
  color: #fff; /* White text on hover for contrast */
  transform: scale(1.03); /* Slightly enlarge on hover */
}

.quick-prompt-bubble:active {
  background-color: #d1551c; /* Darkest shade for active state */
  transform: scale(0.98); /* Slightly shrink for click effect */
}

.chat-form-input {
  gap: 1vw;
  padding-top: 1vh;
  padding-left: 1vw; /*quick-prompts has this too, so good to be in line*/
  display: flex; /*Put both text input and the button on the same row */
  flex-direction: row;
  text-wrap: wrap; 
  overflow: hidden; /*prevents the form from overflowing the chat container*/
}


/*Tables in SAT questions  */
.sat-question-table {
  margin: 0 auto;
}

/*
.skill-buttons {
  /*margin-top: 10px;
} */

.skill-button {
  padding: 12px 20px;
  font-size: 16px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid transparent; /* Do I want this? Add a transparent border that can change color on selection */
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: background-color 0.3s, transform 0.1s, border-color 0.3s;
}

.skill-button:hover {
  transform: scale(1.1);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.skill-button.selected {
  background-color: #2196F3;
  color: white;
  border-color: #1976D2; /* Darker border color to enhance selected effect */
}

.skill-button:not(.selected) {
  background-color: #e0e0e0;
  color: #333333;
}

.skill-button:not(.selected):hover {
  background-color: #d0d0d0;
}

/* Chat Component Styles */
.ChatComponent{
  width: 100%;
  /*New Styles added on 12/13 at 1:50 pm*/
  grid-template-rows: 1fr auto;
  height: 66vh; /* Match your existing max-height */
  /*max-width: 800px;  Match your existing max-width 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;*/
  margin: auto;
  background-color: #ffffff;
}

.chat-container {
  display: flex;
  flex-direction: column;
  max-width: 800px; /* Adjust based on your layout */
  max-height: 66vh; /* Adjust this later for mobile */
  overflow-y: auto; /* enables scrolling */
  margin-top: 1vh; /*Will this conflict with margin: auto? my hope is no*/
  margin: auto;
  padding: 10px;
  border-radius: 10px;
}

.chat-container-active {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Apply the shadow only when active */
  background-color: #ffffff;
}

.chat-input {
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 80%; /* Adjust width as needed */
  font-size: inherit;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: height 0.2s ease-in-out;
  resize: none; /* Prevent manual resizing */
}

.chat-input:focus {
  outline: none;
  border-color: #007AFF; /* Same blue as iPhone text */
  box-shadow: 0 0 5px rgba(0, 122, 255, 0.5);
}

.message {
  padding: 1vh 1vw;
  margin: 5px;
  border-radius: 15px;
  max-width: 90%;
}

.user-message {
  align-self: flex-end;
  /*background-color: #d1e7dd;  Light green background for user messages */
  background-color: #007bff; /* former light blue color: #5FEFEF */
  color: white;
}

.rendered-question{
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block; /*ensure it doesn't take up full width */
  width: 100%;
  text-align: left;
  white-space: normal;
  overflow-wrap: break-word; 
  margin: 0;
}

.rendered-question.small-text{
  font-size: 0.875rem;
  line-height: 1.43;
}

.user-message .rendered-question p{
  color: white !important;
}


.ai-message {
  align-self: flex-start;
  background-color: #BBF896; /* Pleasant light green-yellow for AI chat responses */
  color: #333333;
}

.message p {
  margin: 0;
  color: #333;
}

.no-conversation {
  text-align: center;
  color: #333;
}


/*HomePage styling */
/* Animation for smooth input transitions */
.slide-up {
  animation: slide-up 0.5s ease-out forwards;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

stripe-buy-button {
  background-color: #f16522;
  color: white;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  justify-content: center;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  width: 35% vw;
  height: 50px; /* Example height */
  display: block; /* Ensure it is displayed */
}

stripe-buy-button:hover {
  background-color: #d1551c;
}


.success-message{
  /*
  color: #f16522;
  font-size: 0.9rem;
  */
  margin-top: 0.5rem;
  
}

.login-success-message{
  color: #f16522;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  
}
  


/* Error message styling for form validation */
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}


/* How to spin something (e.g. a logo)
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  */


