/* Container */
.profile-container {
  /*width: 100%;*/
  margin: 0 auto;
  padding: 1.5rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-block-end: 0;
  margin-block-start: 0.5rem;
}  

.hero-content p, .profile-container p, h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }
  

/* Progress Bar */
.progress-bar-container {
  width: 100%;
  background-color: #f3f4f6;
  border-radius: 9999px;
  height: 0.75rem;
  /*margin-bottom: 1.5rem;*/
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.progress-bar {
  background: linear-gradient(90deg, #f16522 0%, #d1151c 100%);
  height: 0.75rem;
  border-radius: 9999px;
  transition: width 500ms ease;
  box-shadow: 0 2px 4px rgba(241, 101, 34, 0.25);
}

/* Headers */
.profile-title {
  font-size: 1.5rem;
  font-weight: 700;
  /*margin-bottom: 0.5rem;*/
  margin-top: 1rem;
  color: #f16522;
}

.step-label {
  color: #666666;
}

/* Buttons */
.button-container {
    display: flex;
    justify-content: center;
    gap: 2vw;
    margin-top: .5rem;
}
  
.header-container {
    /*
margin-bottom: 2rem;*/
display: flex;
flex-direction: column;
} 

.profile-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 300ms ease;
}

.profile-button:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.back-button {
  border: 1px solid #ddd;
  color: #666666;
}

.back-button:hover {
  background-color: #e0e0e0;
}

.back-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.next-button {
  background-color: #f16522;
  color: white;
}

.next-button:hover {
  background-color: #d1151c;
}

/* Form Inputs */
.profile-input {
  width: 95%;
  padding: 0.5rem;
  border: 2px solid #e5e7eb;
  border-radius: 0.5rem;
  transition: all 200ms ease;
  background-color: #f8fafc;
  font-size: 1rem;
}

.profile-input:hover {
  border-color: #d1d5db;
  background-color: white;
}

.profile-input:focus {
  border-color: #f16522;
  box-shadow: 0 0 0 3px rgba(241, 101, 34, 0.1);
  outline: none;
  background-color: white;
}

/* Card Layouts */
.step-container {
  display: flex;
  flex-direction: column;
  /*gap: .5rem;*/
  animation: fadeIn 0.5s ease-in;
}

/* Add subtle animation for page transitions */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Test Selection Buttons */
.test-button-group {
  display: flex;
  gap: 1rem;
  /*margin-bottom: .5rem;
  margin-top: .5rem;*/
}

.test-toggle-button {
  flex: 1;
  padding: 1rem;
  border: 2px solid #ddd;
  border-radius: 0.5rem;
  transition: all 200ms ease;
}

.test-toggle-button:hover {
  border-color: #ccc;
}

.test-toggle-button.active {
  border-color: #f16522;
  background-color: #fff7ed;
}

/* Score Input Groups */
.score-input-container {
  display: grid;
  gap: 1rem;
  margin-top: .5rem;
}

.score-input-group {
  display: flex;
  flex-direction: column;
}

.score-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}

.error-text {
  color: #d1151c;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Goals Section */
.goals-input-container {
  display: flex;
  flex-direction: column;
}

.target-scores-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-content: center;
  margin-right: .5rem;
}

.colleges-section {
  margin-top: .5rem;
}

.major-section {
  margin-top: 0.5rem;
}

.input-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #374151;
}

.textarea-input {
  width: 95%;
  /*min-height: 100px;*/
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  transition: all 200ms ease;
}

.textarea-input:focus {
  border-color: #f16522;
  box-shadow: 0 0 0 1px #f16522;
  outline: none;
}


.margin-top-4 {
  margin-top: .5rem;
}

.flex-center {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #f16522;
}

/* Academic Interests Section */
.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f3f4f6;
}

.motivation-textarea {
  width: 95%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  transition: all 200ms ease;
}

.motivation-textarea:focus {
  border-color: #f16522;
  box-shadow: 0 0 0 1px #f16522;
  outline: none;
}

/* Improvement Areas Section */
.improvement-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

@media (min-width: 768px) {
  .improvement-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.improvement-button {
  padding: .5rem;
  border-radius: 0.5rem;
  border: 2px solid #e5e7eb;
  transition: all 200ms ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.improvement-button:hover {
  border-color: #d1d5db;
}

.improvement-button.active {
  border-color: #f16522;
  background-color: #fff7ed;
}

.improvement-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

/* Base styles for the feedback option buttons */
button.feedback-option {
  display: block;
  width: 95%;
  max-width: 580px;
  margin: 0.5rem auto;
  padding: 0.5rem;
  border: 2px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: white;
  text-align: left;
  cursor: pointer;
  transition: all 200ms ease;
  box-sizing: border-box;
}

/* Hover state */
button.feedback-option:hover {
  border-color: #d1d5db;
  background-color: #f9fafb;
}

/* Active/Selected state - using attribute selector */
button.feedback-option[data-active="true"] {
  border-color: #f16522 !important; /* Using !important temporarily to debug */
  background-color: #fff7ed !important;
}

/* Or alternatively, try using a class approach instead: */
button.feedback-option.active {
  border-color: #f16522 !important;
  background-color: #fff7ed !important;
}

/* Keep your existing title and description styles */
.feedback-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.feedback-description {
  color: #666666;
  font-size: 0.875rem;
}

/* Final Step Section */

.personal-interests-textarea {
  width: 95%;
  /*min-height: 150px;*/
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  transition: all 200ms ease;
}

.personal-interests-textarea:focus {
  border-color: #f16522;
  box-shadow: 0 0 0 1px #f16522;
  outline: none;
}

.alert-container {
  /*display: flex;*/
  align-items: flex-start;
  gap: 0.75rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff7ed;
}

.alert-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #f16522;
  flex-shrink: 0;
  margin-top: 0.125rem;
}

.alert-text {
  color: #374151;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Card.tsx CSS formatting */
.card-base {
  margin: 0 auto;
  width: 95%;
  border-radius: 0.5rem;  /* rounded-lg */
  border: 1px solid #e5e7eb;  /* border */
  background-color: white;  /* bg-card */
  color: #1f2937;  /* text-card-foreground */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);  /* shadow-sm */
}

.card-header-base {
  display: flex;  /* flex */
  flex-direction: column;  /* flex-col */
  gap: 0.375rem;  /* space-y-1.5 */
  padding: .5rem;  /* p-6 */
}

.card-title-base {
  font-size: 1.5rem;  /* text-2xl */
  font-weight: 600;  /* font-semibold */
  line-height: 1;  /* leading-none */
  letter-spacing: -0.025em;  /* tracking-tight */
  margin-left: 1rem;
}

/* Alert.tsx CSS formatting */
.alert-base {
  position: relative;
  width: 95%;
  border-radius: 0.5rem;  /* rounded-lg */
  border: 1px solid #e5e7eb;
  padding: 1rem;  /* p-4 */
}

.alert-default {
  background-color: white;  /* bg-background */
  color: #1f2937;  /* text-foreground */
}

.alert-destructive {
  border-color: rgba(239, 68, 68, 0.5);  /* border-destructive/50 */
  color: rgb(239, 68, 68);  /* text-destructive */
}

.alert-description {
  font-size: 0.875rem;  /* text-sm */
}

.alert-description p {
  line-height: 1.625;  /* [&_p]:leading-relaxed */
}

/* Card.tsx CSS formatting */
.card-content {
  padding: 0 0.5rem .5rem;
  width: 95%;
  margin: 0 auto;
  /* padding-top: 0;   pt-0 */
}

.step5-final-text {
  width: 95%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0.5rem;
}
.step5-final-text p {
  margin-block-start: 0;
  margin-block-end: 0;
}

/* CreateProfile.tsx step 5 email, firstName, password CSS formatting */
.auth-inputs {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}